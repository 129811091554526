import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled"
import {letterSpacing, pageTransitionTimeMs, spacing, transitions} from "../Styles/variables";
import glitch from '../Styles/glitch';
import {ScrollScene} from "scrollscene";
import Button, {buttonStyle} from "../Button";
import isOnMobile from "../../isOnMobile";
import {animateScroll, Link} from "react-scroll";

const PageWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items:center;
  position: relative;
  opacity: 0;
  transition-delay: .5s;
  transition: ${transitions.pageSlow};
  
  &.in-view {
    opacity: 1;
  }
`

const StyledScrollButton = styled(Link)`
    ${buttonStyle};
    display: inline-block;
    width: 100%;
    margin-bottom: ${spacing.md};

    @media only screen and (min-width: 992px) {
        width: auto;
        margin-bottom: 0;
        margin-left: ${spacing.md};
    }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${spacing.md};
  display: inline-block;

    @media only screen and (min-width: 992px) {
    width: auto;
    margin-bottom: 0;
  
    margin-left: ${spacing.md} !important;
  }
`

const Content = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${spacing.md};
`

const Title = styled.h2`
  font-size: 2em;
  position: relative;
  margin: 0;
  color: white;
  letter-spacing: ${letterSpacing.action};
  max-width: 100%;
  z-index: 1;
  text-align: center;
  
  @media only screen and (min-width: 992px) {
    font-size: 4em;
  }
  
  ${glitch}
`

const Text = styled.p`
  margin-top: ${spacing.md};
  text-align: center;
`

const BottomBar = styled.div`
  width: 100%;
  max-width: 600px;
  padding: ${spacing.md};
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  flex-direction: column;
  text-align: center;
  
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${spacing.md};
    margin-top: ${spacing.md};
    
    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }
`

const StartAProject = () => {
    const pageWrapper = useRef(null);
    const content = useRef(null);
    let currentMouse = {x:0, y: 0}
    let previousMouse = {x:0, y:0}
    const currentDifference = {x: 0, y:0}

    const mouseUpdate = ({screenY, screenX}) => {
        currentMouse = {y: screenY, x: screenX}
    }

    const mailMe = () => {
        window.open("mailto:info@midasminnegal.nl?subject=Hey Midas, I'd like to get in touch!');")
    }

    const slowDownContentMultiplier = 75;

    const animate = () => {
        requestAnimationFrame( animate );
        if(!content?.current?.style) return;

        const difference = {
            x: previousMouse.x - currentMouse.x,
            y: previousMouse.y - currentMouse.y
        }

        currentDifference.x += difference.x
        currentDifference.y += difference.y

        content.current.style.transform = `translate(${currentDifference.x / slowDownContentMultiplier + 55}px, ${currentDifference.y / slowDownContentMultiplier}px)`

        previousMouse = currentMouse
    }

    useEffect(() => {
        if(!isOnMobile()) {
            animate()
        }
    }, [content.current]);

    useEffect(() => {
        new ScrollScene({
            triggerElement: pageWrapper.current,
            toggle: {
                element: pageWrapper.current,
                className: "in-view"
            },
            offset: pageWrapper.current.offsetHeight / 2,
        })

        pageWrapper.current.addEventListener("mousemove", mouseUpdate, false);
    }, [pageWrapper.current])

    return (
        <PageWrapper ref={pageWrapper} id="#start-a-project">
            <Content ref={content}>
                <Title data-text="LETS COLLABORATE!">LETS COLLABORATE!</Title>
                <Text>We can take on the world, but only if we do it together!</Text>
                <ButtonWrapper>
                    <StyledScrollButton isA={true} href="#contact-me" to="contact-me" smooth data-text="Contact me">Contact me</StyledScrollButton>
                </ButtonWrapper>
            </Content>
            <BottomBar>
                <span>KVK 78635039</span>
                <span>BTW NL003357931B14</span>
            </BottomBar>
        </PageWrapper>
    )
}

export default StartAProject
