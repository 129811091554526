import React, {useEffect, useRef} from 'react';
import styled from "@emotion/styled";
import {spacing, transitions} from "../Styles/variables";
import {Divider, ResumeTitle} from "../Styles/Resume";
import data from "./resumeContent";
import {ScrollScene} from "scrollscene";

const Wrapper = styled.div`
  margin: calc(${spacing.lg} * 2) 0 ;

  @media print {
    margin: 0;
  }
`

const TraitWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const Trait = styled.div`
  flex: 0 0 calc(100% / 3);
  text-align: center;
  padding: ${spacing.md};
  transition: ${transitions.base};
  transition-delay: ${({index}) => 1 + index/5}s;
  opacity: 0;
  transform: translateY(${spacing.md}) scale(.9);
  
  &.in-view {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  @media print {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  @media only screen and (max-width: 992px) {
    flex: 0 0 100%;
    transition-delay: ${({index}) => 0.5 + index/5}s;
  }
`

const Traits = () => {
  const pageWrapper = useRef(null);
  const traitsWrapper = useRef(null);

  useEffect(() => {
    if(Boolean(traitsWrapper.current) && Boolean(pageWrapper.current)) {
      for(let element of traitsWrapper.current.children) {

        new ScrollScene({
          triggerElement: pageWrapper.current,
          toggle: {
            element: element,
            className: "in-view"
          },
        })
      }
    }
  }, [])

  return (
    <Wrapper ref={pageWrapper}>
      <ResumeTitle data-text={data.traits.title}>{data.traits.title}</ResumeTitle>
      <Divider />
      <TraitWrapper ref={traitsWrapper}>
        {data.traits.items.map(({title, description}, index) => (
          <Trait index={index} key={title} >
            <h3>{title}</h3>
            <p>{description}</p>
          </Trait>
        ))}
      </TraitWrapper>
    </Wrapper>
  )
}

export default Traits