import React, {useEffect, useRef} from 'react'
import {ScrollScene} from "scrollscene";
import styled from "@emotion/styled";
import {spacing, transitions} from "../Styles/variables";

const Wrapper = styled.div`
  width: 100%;
  margin: calc(${spacing.lg} * 2) 0;
  text-align: center;
  opacity: 0;
  transform: translateY(${spacing.xl});
  transition: ${transitions.page};
  
  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  @media only screen and (max-width: 992px) {
    text-align: left;
    padding: 0;
  }
`

const Text = styled.p`
  max-width: 700px;
  margin: ${spacing.md} auto 0 auto;
`

const TextCenter = ({text, title}) => {
  const wrapper = useRef(null)

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

  return (
    <Wrapper ref={wrapper}>
      {title && (<h1>{title}</h1>)}
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default TextCenter