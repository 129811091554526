import React from 'react'
import styled from "@emotion/styled";
import {spacing} from "../Styles/variables";
import Button from "../Button";
import resume from './Midas-Minnegal-Resume.pdf'

const Wrapper = styled.div`
    text-align: center;
`

const ButtonWrapper = styled.div`
    display: flex;
    padding-top: ${spacing.sm};
    gap: ${spacing.md};
    justify-content: center;

    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }
`

const pdfLocation = 'https://www.midasminnegal.nl/resume.pdf'

const OpenResumeBlock = ({onClickHandler = () => null }) => (
  <Wrapper>
    <h2>Take a look at my resume</h2>
    <p>Do you want to download my resume, or open it in a new window?</p>
    <ButtonWrapper>
      <Button onClick={onClickHandler} href={pdfLocation} target={"_blank"} isA={true} text="Open in new window"/>
      <Button onClick={onClickHandler} text="Download PDF" isA={true} href={resume} download="Midas-Minnegal-Resume.pdf"/>
    </ButtonWrapper>
  </Wrapper>
)

export default OpenResumeBlock