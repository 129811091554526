import {faCalendarDays, faPhone, faHouseChimney, faEnvelope} from "@fortawesome/free-solid-svg-icons";

const today = new Date()

const getAgeFromDate = (date, fallback = 'couple') => {
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--
  return isNaN(age) ? fallback : age
}

const data = {
  title: 'MIDAS MINNEGAL RESUME',
  header:
    [
      {
        icon: faCalendarDays,
        text: '24-10-1996',
      },
      {
        icon: faHouseChimney,
        text: 'Enschede'
      },
      {
        icon: faEnvelope,
        text: 'info@midasminnegal.nl',
        aHref: 'mailto:info@midasminnegal.nl',
      },
      {
        icon: faPhone,
        text: '+31 6 8389 2252',
        aHref: 'tel:+31683892252',
      }
    ],
  languages: [
    {
      title: 'Dutch',
      level: 'Native'
    },
    {
      title: 'English',
      level: 'Professional'
    }
  ],
  intro: `I am a socially skilled ${getAgeFromDate('1996-10-24 00:00:00', 26)} year old front end developer, who also has know-how of back-end development and can 
  function in a full-stack role. Due to the healthy mix of studies that I have followed, I’ve gathered a good bunch of not
   only technical, but also design knowledge which I both apply in my works. In the past ${getAgeFromDate('2015-01-08 00:00:00', 7)} years I’ve worked part-time in
    the software industry at various companies, mostly in a front-end development role. The reason that I became a front-end
     engineer is because I enjoy creating visual applications which interest people, creating a wow effect.`,
  education: {
    title: 'EDUCATION',
    items: [
      {
        title: 'Master Interaction technology',
        location: 'University of Twente',
        description: `This study program is set in a field where technology is looked at from the end 
        users perspective in an academic way. This program offered technical courses too like machine learning and natural language processing.`,
        time: '2022 - 2024',
        avgGrade: '7.6'
      },
      {
        title: 'Pre-master Interaction technology',
        location: 'University of Twente',
        description: `This was the first real encounter I’ve had with scientific research. This programme only takes 6 
        months to complete, but I really improved in problem solving during this time. Another thing that majorly improved
         during this time were my mathematical skills.`,
        time: '2021 - 2022',
        avgGrade: '7.3'
      },
      {
        title: 'Software engineering',
        location: 'Saxion - University of applied sciences',
        description: `This study program was back-end centric, with front end aspects like JavaScript development. 
        For a front-end developer it is important to know about back-end practices, because a front-end can not function 
        properly without a back-end. The study programme also puts emphasis on processes used in software development.`,
        time: '2017 - 2021',
        avgGrade: '7.9'
      },
      {
        title: 'Interactive design',
        location: 'De Maere - ROC of Twente',
        description: `At this study programme I learned designing interactive applications. It is the place where I developed 
        my first JavaScript, HTML, and CSS knowledge, and where I found my love for front-end development. I also learned how 
        to work with the Adobe CC programs.`,
        time: '2013 - 2017',
        avgGrade: '8.0'
      }
    ]
  },
  work: {
    title: 'WORK EXPERIENCE',
    items: [
      {
        title: 'Web Development Midas Minnegal',
        location: 'Freelance front-end developer',
        description: `Currently, I work next to my study as a freelance software developer. As a freelancer, I can take 
        on the entire process, from designing the application to developing the front and back-end, however I’ve also joined 
        teams in the role as a front-end developer at various companies. Being a freelancer, I’ve learned how to carry the
         responsibilities directly of projects with stakeholders.`,
        time: '2020 - current'
      },
      {
        title: 'TicketSwap',
        location: 'Front-end developer',
        description: `TicketSwap is a ticket resale platform that currently dominates the Dutch market and is getting big 
        in other international markets as well. I’ve done an internship here, where I have worked full time in a multidisciplinary
         scrum team on improving and further developing the website. At TicketSwap code quality is very important. Hence, 
         during my time here, the quality of my code increased significantly.`,
        time: '2019 - 2020'
      },
      {
        title: 'TRIMM',
        location: 'Front-end developer',
        description: `TRIMM is one of the biggest digital agencies in The Netherlands. At this company, I’ve helped develop
         websites for big companies like Maxi-Cosi, Yousee (The biggest internet provider of Denmark), Philips, Unicarriers 
         OneForAll, and more in a fast-paced environment.`,
        time: '2016 - 2019',
      },
      {
        title: 'Webpact',
        location: 'Web developer',
        description: `WebPact is a small company situated in Oldenzaal. I’ve helped develop sites for small local businesses.
         This was a fast-paced working environment. Websites usually took about a week to complete. The company also owns multiple
          vacation sites which reach hundreds of thousands visitors annually.`,
        time: '2015 - 2016',
      }
    ]
  },
  skills: {
    title: 'SKILLS',
    items: [
      'HTML', 'SCSS', 'JavaScript', 'React', 'React Native', 'NextJs', 'Meteor', 'Vue', 'NuxtJs', 'Ionic', 'Python', 'Kotlin', 'Java', 'C++', 'PHP', 'Drupal 8', 'Contentful', 'GraphQl', 'MySql', 'MongoDB', 'Machine learning', 'NPM', 'Composer', 'Git', 'Scrum', 'MacOs', 'Linux', 'Windows', 'Interactive design', 'Graphical design', 'Photoshop', 'Indesign', 'Premiere Pro'
    ]
  },
  traits: {
    title: 'Traits',
    items: [
      {
        title: 'FAST LEARNER',
        description: 'I tend to pick up things quickly and I always want to learn new things. I can not handle not understanding something, so I’ll make sure that I figure everything out.'
      },
      {
        title: 'TEAM PLAYER',
        description: 'I like to work together with other people to share knowledge and build better products. I’m not the type of person who is going to sit back and watch how other people solve problems. I like to give input and share my opinion.'
      },
      {
        title: 'CREATIVE',
        description: 'I’ve got a creative mind. This allows me to think out of the box and come up with easy solutions for complex problems.'
      },
    ]
  }
}

export default data