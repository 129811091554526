import wdyd from './images/wdyd/wdyd.png'
import wdydChat from './images/wdyd/wdyd-chat.jpg'
import wdydHybrid from './images/wdyd/wdyd-hybrid.jpg'
import wdydQuestion from './images/wdyd/wdyd-question.jpg'
import wdydQuestionOverview from './images/wdyd/wdyd-question-overview.jpg'
import light from './images/twenteLight/light.jpg'
import iSayYes from './images/i-say-yes/i-say-yes.jpg'
import iSayYesResp from './images/i-say-yes/i-say-yes-responsive.jpg'
import iSayYesConf from './images/i-say-yes/i-say-yes-configure.jpg'
import ca from './images/ca/ca.jpg'
import caComp from './images/ca/comprehension.jpg'
import caMus from './images/ca/museum.jpg'
import caDone from './images/ca/done.jpg'
import caFact from './images/ca/random-fact.jpg'
import lightImpression from './images/twenteLight/light-impression.jpg'
import lightExample from './images/twenteLight/light-example.jpg'
import cloudomotionComponents from './images/cloudomotion/cloudomotion-comp.jpg'
import cloudomotionContent from './images/cloudomotion/cloudomotion-content.jpg'
import cloudomotion from './images/cloudomotion/cloudomotion.jpg'

const stack = {
  PYTHON: 'Python',
  REACT: 'React',
  NEXT: 'NextJS',
  VUE: 'Vue.JS',
  NUXT: 'Nuxt',
  NPM: 'NPM',
  IONIC: 'Ionic',
  DOTNET: 'ASP.NET',
  CONTENTFUL: 'Contentful',
}

const tag = {
  UNI: 'Uni Project',
  PRO: 'Professional project'
}

export const contentTypes = {
  TEXT_IMAGE: 'textImage',
  TEXT_IMAGE_REVERSE: 'textImageReverse',
  TEXT_CENTER: 'textCenter',
  TIMELINE: 'timeline',
}

export default [
  {
    tag: tag.PRO,
    name: 'Pure Me',
    introduction: 'A client wanted to connect like-minded young people with each other in an app, and to inspire them to collaborate together on projects. This project started off as a group project for my study. The client decided to hire me when the educational course was over. The app is an advanced prototype which can be used to test initial functionalities with a small group of users, and to show stakeholders and investors what the potential of the app is.',
    url: 'pure-me',
    buildWith: [stack.REACT, stack.IONIC, stack.DOTNET],
    teaser: wdyd,
    client: {
      value: "What Do You Do"
    },
    type: 'App Design & Development',
    content: [
      {
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'One App, All Devices',
        text: 'This app was build using Ionic, which is a hybrid-app framework. This means that you only have to develop one app, and it will work on both, Android, and Iphones. You can even convert this app to a website if you want!',
        image: wdydHybrid,
      },
      {
        type: contentTypes.TEXT_CENTER,
        text: "The main goal of the client was to connect like minded people. In order to find these like minded people, the app first needs to get to know the user. This is done via personality tests in the form of questionnaires."
      },
      {
        type: contentTypes.TEXT_IMAGE,
        title: 'Personality Tests Galore',
        text: 'In first instance, the client asked for two personality tests to be implemented in the app. This number of required personality tests increased over time, so a scaleable solution was build. At this moment it hosts 12 personality tests with all different outcome screens. The progress of the tests is shown individually in the overview so that a user can see where they left off.',
        image: wdydQuestionOverview,
      },{
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'Answer Questions And Get To Know Yourself',
        text: 'Every personality test consists of a set of multiple choice questions. You can fill them out in order to see who you are as a person.',
        image: wdydQuestion,
      },{
        type: contentTypes.TEXT_IMAGE,
        title: 'A Complete App With Build-In Chat',
        text: 'When you have completed the mandatory questionnaires, A chat function is unlocked where you can talk to like minded people. These like minded people are selected based on the answers given during the questionnaires. The goal of the chat is to connect people and stimulate them to start projects together, which can also be done inside of the app.',
        image: wdydChat,
      },
      {
        type: contentTypes.TEXT_CENTER,
        text: "Everyone who has unlocked the chat function can start a project in the app. Users of the app can then decide to collaborate on this project which allows them to chat with each other."
      },
      {
        type: contentTypes.TIMELINE,
        title: 'Project Timeline',
        items: [
          "The project started of as a group project for my study at Saxion, University of Applied Science.",
          "I took on the role of product owner, designer, and lead front-end developer since I was the only person in the group with experience in design and the most experienced person in front-end development.",
          "Requirements and a first design were made in order to ensure that what is being developed is the ideal solution for the client.",
          "Development started. I mainly worked together with some group members on the front-end. Simultaneously the back-end was developed by other group members.",
          "The scope of the app was too big. The school project ended whilst the app was not finished. This was expected to happen and the customer was aware of this. He decided to hire me to keep working on the app.",
          "I continued working on the app, but now as the sole developer.",
        ]
      },
      {
        type: contentTypes.TEXT_CENTER,
        text: "The app is build with React and Ionic. This allows for app development in a web-like style, which ensures rapid development which is great for prototype apps."
      },
    ],
  },
  {
    tag: tag.PRO,
    name: 'Twente Light',
    introduction: 'Twente Light is an event which is organised anually at Vliegveld Twenthe. During the night, beautiful works of art are set on display. These works of art are lit up from the inside to provide the guests with a unique colorful experience in the dark. I was hired by an agency to develop the website for this event, whilst this agency delivered the designs and maintained contact with the customer.',
    url: 'twente-light',
    buildWith: [stack.VUE, stack.NUXT, stack.CONTENTFUL],
    teaser: light,
    client: {
      value: "UNCOMMON",
      url: 'https://www.uncommon.nl/'
    },
    type: 'Website Development',
    projectUrl: 'https://www.twentelight.nl/',
    content: [
      {
        type: contentTypes.TEXT_IMAGE,
        title: 'Not A Template Site',
        text: 'Website templates are often used when sites for smaller events are built for an affordable price. The usage of a template often leads to restrictions in design, resulting in your site not 100% matching your brand. I build every site or app custom to perfectly match your brand. UNCOMMON designed the perfect website for their client, and I build it without any cuts.',
        image: lightExample,
      },
      {
        type: contentTypes.TIMELINE,
        title: 'Project Timeline',
        items: [
          "After designing the website, an enquiry for this project was made by UNCOMMON.",
          "The designs where shown to me, and I estimated a price, which was later agreed on.",
          "Development started, and the site was built according to the design.",
          "UNCOMMON tested the site and gave feedback on it.",
          "The feedback was processed, and the site went live for all the customers to see.",
        ]
      },
      {
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'Content Management Is A Breeze',
        text: 'The Contentful CMS was used as a backend for this website. All the content, including all imagery is bi-lingual and can easily be changed with this CMS. This allows the customer to update text within minutes everytime this event is organized. This ensures that the  site will last for the coming years.',
        image: lightImpression,
      },
    ],
  },
  // {
  //   tag: tag.PRO,
  //   name: 'Modular Components',
  //   introduction: 'These are modular components that I build for a client.',
  //   url: 'modular-components',
  //   buildWith: [stack.VUE, stack.NPM],
  //   teaser: alpaca,
  //   type: 'Web Components Development',
  //   content: [],
  // },
  {
    tag: tag.PRO,
    name: 'Honey, I say Yes',
    introduction: 'The client from Honey, I Say Yes is a wedding official who used to work for the local authority, but is now starting out on her own. Because she is starting out new, I got to develop het entire brand from scratch together with her. The content of the site is not done yet, but the site will be live shortly!',
    url: 'i-say-yes',
    buildWith: [stack.REACT, stack.NEXT, stack.CONTENTFUL],
    teaser: iSayYes,
    client: {
      value: 'Honey, I say yes',
    },
    type: 'Website Development & design',
    content: [
      {
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'A Design For Every Display',
        text: 'The site is designed in close corporation with the client with every screen in mind. This ensures that the site can be accessed by anyone from anywhere.',
        image: iSayYesResp,
      },
      {
        type: contentTypes.TIMELINE,
        title: 'Project Timeline',
        items: [
          "An enquiry was made for a website. The client had a global idea of what she wanted, and provided me with an example site which she liked.",
          "Based on this example site and the wishes of the client, a global styleguide was created and approved by the client.",
          "Development and design were done in the same phase. Every few days when progress was made, a demo of the site with the new design was given to the client to collect feedback and to ensure that the site is what the client wants.",
          "An elaborate guide on how to manage the content of the site was written and delivered for the client.",
          "The client is currently writing the proper content for the site.",
        ]
      },
      {
        type: contentTypes.TEXT_IMAGE,
        title: 'Personalized Custom Components',
        text: 'Custom components are designed and developed in order to perfectly match the clients business logic.',
        image: iSayYesConf,
      },
    ],
  },
  {
    tag: tag.PRO,
    name: 'Cloudomotion',
    introduction: 'Cloudomotion is a company which digitalizes organizations, especially in the carpentry industry. This digitalization is done with software. For this project UNCOMMON reached out to me. They designed a corporate website for the Cloudomotion brand which I had to build.',
    url: 'cloudomotion',
    buildWith: [stack.VUE, stack.NUXT, stack.NPM, stack.CONTENTFUL],
    teaser: cloudomotion,
    projectUrl: 'https://www.cloudomotion.nl',
    client: {
      value: "UNCOMMON",
      url: 'https://www.uncommon.nl/'
    },
    type: 'Website Development',
    content: [
      {
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'Modular Components',
        text: 'This site uses a NPM package called "Modular Components" which I developed commissioned for UNCOMMON. It is a component library that is fully customizable via the altering of CSS variables. The site for Cloudomotion among others includes a sizeable amount of these components, allowing for faster development of the site.',
        image: cloudomotionComponents,
      },
      {
        type: contentTypes.TIMELINE,
        title: 'Project Timeline',
        items: [
          "UNCOMMON reached out to me with the design, and enquired a price for the development for this website.",
          "A price was estimated, agreed upon, and the development phase could begin.",
          "The website was developed using the Modular Components NPM package.",
          "UNCOMMON tested the site and gave feedback on it.",
          "The feedback was processed quickly so that the site could be delivered to the client.",
          "The client added the correct content to the site.",
          "The site went live for the world to see.",
        ]
      },
    ],
  },
  {
    tag: tag.UNI,
    name: 'Museum Chat Bot',
    introduction: 'In museums you often have the possibility to be guided by a tour guide. These guides are labour intensive, making them expensive, and are often conducted in a group setting, leaving limited time for questions. This is why at some museums chat-bot apps have made an appearance. For the Master course "Advanced Project in Conversational Agents" at the University of Twente, I together with another student conducted an experiment to find out if these chat-bots should engage in conversation, or wait for the guests to answer questions.',
    url: 'chat-bot',
    buildWith: [stack.REACT, stack.PYTHON],
    teaser: ca,
    client: {
      value: "University of Twente",
      url: "https://www.utwente.nl"
    },
    type: 'Scientific Research',
    content: [
      {
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'Online Museum',
        text: 'In order to conduct this experiment, an online museum was constructed. Participants could go to the previous and next paintings by clicking arrows on the sides of the screen.',
        image: caMus,
      },
      {
        type: contentTypes.TEXT_IMAGE,
        title: 'Experiment Setup',
        text: 'The experiment was set up in two phases. In one phase, the participant would chat with an engaging chat-bot, and in one phase they would talk with a passive chat-bot. It was random which phase they would encounter first, to avoid a learning effect. When a phase is done, the participant was notified.',
        image: caDone,
      },
      {
        type: contentTypes.TEXT_IMAGE_REVERSE,
        title: 'Engaging VS Passive Chat-Bot',
        text: 'The engaging chat-bot would give random facts about the painting, and when a participant goes to the next painting, it will introduce it briefly. The passive chat-bot would only inform the participants at the beginning of the experiment that they can ask questions.',
        image: caFact
      },
      {
        type: contentTypes.TEXT_CENTER,
        title: 'Information Gathering',
        text: 'Information was gathered using SparQl queries from wikidata.org. Wikidata.org is a data web, which allows one to not only gather information about a painting, but also about related things like the artist. When a message is send to the chat-bot, a bag-of-words approach is used. Each word is analyzed and a probability is calculated for what the participant is asking. After this a SparQl query is constructed for the most probable thing that the participant is asking, which is send to wikidata.org.'
      },
      {
        type: contentTypes.TEXT_IMAGE,
        title: 'Understanding A Human',
        text: 'The chat-bot is able to identify nonsense answers, which happened more often than not during the experiment.',
        image: caComp
      },
      {
        type: contentTypes.TEXT_CENTER,
        title: 'Results Of The Experiment',
        text: 'There was not a significant preference found between the passive and engaging chat-bot. This might have been caused by the small sample size of only 10 participants. It still was interesting however to develop such a chat-bot, which could interpret questions, and get the answers to these questions from the internet.'
      },
    ]
  },
]