import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled"
import {colors, letterSpacing, spacing, transitions} from "../Styles/variables"
import { ScrollScene } from 'scrollscene'
import ScrollIcon from "../ScrollIcon"
import {keyframes} from "@emotion/react"
import { Link } from "react-scroll"
import glitch from "../Styles/glitch";

const IconAnimationActive = keyframes`
  0%{
    transform: translateY(5px);
  }
  50%{
    transform: translateY(30px);
  }
  100%{
    transform: translateY(5px);
  }
`

const LandingWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  z-index: 2;

  .animated-text {
    opacity: 0;
    transition-delay: .3s;
    transition: ${transitions.pageSlow};
  }

  &.in-view {
    .animated-text {
      opacity: 1;
    }
  }
`

const ScrollDown = styled(Link)`
  cursor: pointer;
  color: white;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: ${letterSpacing.action};
  font-weight: bold;
  opacity: .5;
  transition: ${transitions.base};
  z-index: 1;
  margin-bottom: calc(${spacing.lg} * 2);
  position: absolute;
  bottom: ${spacing.md};
  left: 50%;
  transform: translateX(-50%);
  outline: 0;

  @media only screen and (max-width: 992px) {
    margin-bottom: ${spacing.lg};
  }

  &:hover,
  &:focus {
    opacity: 1;

    svg g {
      animation: ${IconAnimationActive} 1s infinite;
    }

    .scroll-text {
      ${glitch};
    }
  }
`

const ScrollText = styled.span`
  margin-top: 15px;
`

export const Glitch = styled.h1`
  position: relative;
  color: ${colors.white};
  font-size: 2.5em;
  letter-spacing: ${letterSpacing.action};
  text-align: center;
  max-width: 100%;
  z-index: 1;
  padding: 0 ${spacing.md};

  @media only screen and (min-width: 992px) {
    font-size: 4em;
  }

  ${glitch}
`

const StyledGlitch = styled.h2`
    position: relative;
    color: ${colors.white};
    font-size: 1.5em;
    letter-spacing: ${letterSpacing.action};
    text-align: center;
    max-width: 100%;
    z-index: 1;
    padding: 0 ${spacing.md};
    
  @media only screen and (min-width: 992px) {
    font-size: 2.125rem;
  }

  ${glitch}
`

const Landing = () => {
  const wrapper = useRef(null)

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

  return(
    <>
      <LandingWrapper ref={wrapper}>
        <div>
          <Glitch className="animated-text" data-text="MIDAS MINNEGAL">MIDAS MINNEGAL</Glitch>
          <StyledGlitch className="animated-text" data-text="DEVELOPMENT AND DESIGN">DEVELOPMENT AND DESIGN</StyledGlitch>
        </div>
        <ScrollDown className="mouse-hover" href="#about-me" to="about-me" smooth>
          <ScrollIcon />
          <ScrollText className="scroll-text" data-text="ABOUT ME">
            ABOUT ME
          </ScrollText>
        </ScrollDown>
      </LandingWrapper>
    </>
  )
}

export default Landing
