import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled";
import {colors, spacing, transitions} from "../Styles/variables";
import TimelineLeave from "./TimelineLeave";
import {ScrollScene} from "scrollscene";

const Wrapper = styled.div`
  text-align: center;
  
  &.in-view {
    .timeline-title {
      opacity: 1;
      transform: translateY(0);
    }
    
    .timeline {
      &::before {
        height: 100%;
        opacity: 1;
      }
    }
  }
`

const Line = styled.div`
  position: relative;
  padding: ${spacing.xl};
  display: flex;
  flex-direction: column;
  
  &::before {
    content: "";
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: ${spacing.sm};
    position: absolute;
    top: 0;
    background-color: ${colors.grey};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 1) ${spacing.xl}, rgba(34, 34, 34, 1) calc(100% - ${spacing.xl}), rgba(34, 34, 34, 0) 100%);
    transition: ${transitions.pageSlow};
    transition-delay: 1s;
    border-radius: ${spacing.sm};
  }
  
  @media only screen and (max-width: 992px) {
    padding: ${spacing.md} 0 ${spacing.md} calc(${spacing.lg} + ${spacing.md});
    
    &::before {
      left: ${spacing.md};
    }
  }
`

const SectionTitle = styled.span`
  letter-spacing: 5px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  padding-bottom: ${spacing.md};
  border-bottom: 1px solid ${colors.mediumGrey};
  margin-bottom: ${spacing.lg};
  transition: ${transitions.pageSlow};
  transition-delay: 1s;
  opacity: 0;
  transform: translateY(${spacing.lg});

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

const Timeline = ({title, items}) => {
  const wrapper = useRef(null)

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

  return (
    <Wrapper ref={wrapper}>
      <SectionTitle className="timeline-title">{title}</SectionTitle>
      <Line className="timeline">
        {items.map((item, index) => <TimelineLeave index={index} key={item}>{item}</TimelineLeave>)}
      </Line>
    </Wrapper>
  )
}

export default Timeline
