import styled from "@emotion/styled";
import {borderRadius, colors, spacing, transitions} from "./variables";

export const Pill = styled.div`
  padding: ${spacing.sm} ${spacing.md};
  border: 1px solid ${colors.white};
  border-radius: ${borderRadius.lg};
  text-align: center;
  display: inline-block;
  margin: 0 ${spacing.md} ${spacing.md} 0;
  transition: ${transitions.base};
  opacity: 0;
  transform: translateY(calc(${spacing.lg}/2)) scale(.9);
  transition-delay: ${({index}) => index / 20 + 0.25}s;

  &.in-view {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  
  @media print {
    color: #222;
    border: 1px solid #222;
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`
