import React, {useRef, useState} from 'react'
import {Input, InputIcon, InputLabel, InputWrapper, TextArea} from "../Styles/input";
import Button from "../Button";
import styled from "@emotion/styled";
import {borderRadius, colors, spacing, transitions} from "../Styles/variables";
import {css} from "@emotion/react";
import {Alert} from "../Styles/alert";
import emailjs from "@emailjs/browser";
import {containerWidth} from "../Styles/containers";

const DetailsTitle = styled.span`
  letter-spacing: 5px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  width: 100%;
  padding-bottom: ${spacing.md};
  border-bottom: 1px solid ${colors.mediumGrey};
`

const ContactWrapper = styled.form`
  max-width: ${containerWidth};
  padding: ${spacing.md};
  margin: 0 auto;
  
  ${({sending}) => sending && css`
    pointer-events: none;
    opacity: .5;
  `};

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: ${spacing.lg} ${spacing.md};
    border-radius: ${borderRadius.lg};
  }
`

const InvisibleInput = styled.input`
  display: none;
`

const ContactForm = () => {
  const form = useRef(null)
  const emptyInput = useRef()

  const [inputName, setInputName] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputMsg, setInputMsg] = useState('')
  const [emailSending, setEmailSending] = useState(false)
  const [emailSend, setEmailSend] = useState(false)
  const [emailFailed, setEmailFailed] = useState(false)

  const getContactButtonDisabled = () => {
    const regex = new RegExp(".+@.+\\..+")
    const isValidEmail = inputEmail.match(regex)

    return !(isValidEmail && Boolean(inputMsg) && Boolean(inputName))
  }

  const sendMail = (e) => {
    e.preventDefault()

    if (!getContactButtonDisabled() && !emptyInput.current.value) {
      setEmailSending(true);

      emailjs.sendForm('service_ni3fzfc', 'template_anorg9t', form.current, 'cLDpxbwx4_vl37ItN')
        .then(() => {
          setInputName('')
          setInputEmail('')
          setInputMsg('')

          setEmailSending(false)

          setEmailSend(true)
          setTimeout(() => {
            setEmailSend(false)
          },5000)

        }, () => {
          setEmailSending(false)

          setEmailFailed(true)
          setTimeout(() => {
            setEmailFailed(false)
          }, 5000);
        })
    }
  }

  return (
    <>
      <Alert show={emailSend}>Email send successfully! Expect a reply soon :)</Alert>
      <Alert danger={true} show={emailFailed}>Failed to send email :( Try again later</Alert>
      <ContactWrapper ref={form} sending={emailSending} onSubmit={sendMail}>
        <DetailsTitle>
          GET IN TOUCH WITH ME!
        </DetailsTitle>
        {/*<ContactTitle>Or contact me!</ContactTitle>*/}
        <InputWrapper>
          <Input name="user_name" placeholder=" " onChange={(e) => setInputName(e.target.value)} value={inputName} required/>
          <InputIcon />
          <InputLabel>
            Your name
          </InputLabel>
        </InputWrapper>
        <InputWrapper>
          <Input name="user_email" pattern=".+@.+\..+" onChange={(e) => setInputEmail(e.target.value)} value={inputEmail} placeholder=" " required/>
          <InputIcon />
          <InputLabel>
            Your email
          </InputLabel>
        </InputWrapper>
        <InputWrapper>
          <TextArea name="message" rows="6" placeholder=" " onChange={(e) => setInputMsg(e.target.value)} value={inputMsg} required/>
          <InputIcon />
          <InputLabel>
            Message
          </InputLabel>
        </InputWrapper>
        <InvisibleInput ref={emptyInput} />
        <Button type="submit" disabled={getContactButtonDisabled()} text="CONTACT ME" />
      </ContactWrapper>
    </>
  )
}

export default ContactForm