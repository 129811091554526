import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled"
import {borderRadius, colors, spacing, transitions} from "../Styles/variables";
import {Divider, ResumeTitle} from "../Styles/Resume";
import {keyframes} from "@emotion/react";

const enterAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(${spacing.md});
    pointer-events: none;
  }
  75% {
    opacity: 0;
    transform: translateY(${spacing.md});
    pointer-events: none;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  margin: calc(${spacing.lg} * 2) 0;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${spacing.md} -${spacing.md} 0;
  animation: ${enterAnimation} 1.5s;
`

const EducationCard = styled.div`
  flex: 0 0 40%;
  padding: ${spacing.md};
  border-radius: ${borderRadius.lg};
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: ${spacing.md};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  transition: .1s;

  &::before {
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: ${transitions.base};
    opacity: 0;
  }
  
  &:hover {
    background-color: rgba(255, 255, 255, .1);
    &::before {
      backdrop-filter: blur(10px);
    }
    
    .grade {
      background-position: 100% 50%;
    }
  }

  @media only screen and (max-width: 992px) {
    flex: 0 0 100%;
    gap: 0;
    margin-bottom: ${spacing.lg};
    transform: none !important;
    
    &:hover { background-color: transparent;}
  }
`

const CardTitle = styled.h3`
  margin: 0;
  text-transform: uppercase;
`;

const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardInfoItem = styled.small`
  opacity: .5;
  font-weight: bold;
`

const CardText = styled.p`
  margin: ${spacing.md} 0 0;
  display: block;
  
  &.has-grade {
    margin: ${spacing.md} 0;
  }
`;

const AvgGrade = styled.span`
  display: flex;
  margin-top: auto;
  font-family: 'Anton', sans-serif;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  justify-self: flex-end;
  
  @media print {
    color: #DDD;
  }
`

const Grade = styled.span`
  background: ${colors.white};
  background: linear-gradient(45deg, ${colors.white} 0%, ${colors.white} 40%, ${colors.glitchPink} 60%, ${colors.glitchBlue} 100%);
  background-origin: content-box;
  background-size: 350%;
  transition: .5s ease;
  background-position: left;
  -webkit-background-clip: text;
  color: transparent;
  transform: scale(1.3);
  font-size: 1.25rem;
  margin-left: ${spacing.md};

  @media only screen and (max-width: 992px) {
    background-position: right;
  }
  
  @media print {
    color: #222;
  }
  
`

const CardsBlock = ({title, items}) => {
  const THRESHOLD = 10;

  const cardWrapperRef = useRef(null)
  const pageWrapper = useRef(null)

  const handleHover = (e, card) => {
    const { clientX, clientY, currentTarget } = e;
    const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;
    const scrollTop = document.body.getBoundingClientRect().top

    const horizontal = (clientX - offsetLeft) / clientWidth;
    const vertical = (clientY - offsetTop - scrollTop) / clientHeight;
    const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
    const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

    card.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
  }

  const resetStyles = (e, card) => {
    card.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
  }

  useEffect(() => {
    if(Boolean(cardWrapperRef.current) && Boolean(pageWrapper.current)) {
      for(let element of cardWrapperRef.current.children) {
        element.addEventListener("mousemove", (e) => handleHover(e, element));
        element.addEventListener("mouseleave", (e) => resetStyles(e, element));
      }
    }
  }, [])

  return (
    <Wrapper ref={pageWrapper}>
      <ResumeTitle data-text={title}>{title}</ResumeTitle>
      <Divider />
      <CardWrapper ref={cardWrapperRef}>
        {items.map(({title, description, location, time, avgGrade}, index, array) => (
          <EducationCard key={title} index={index} length={array.length}>
            <CardTitle>{title}</CardTitle>
            <CardInfo>
              <CardInfoItem>
                {location}
              </CardInfoItem>
              <CardInfoItem>
                {time}
              </CardInfoItem>
            </CardInfo>
            <CardText className={Boolean(avgGrade) ? 'has-grade' : ''}>{description}</CardText>
            {Boolean(avgGrade) && <AvgGrade >Average grade: <Grade className="grade">{avgGrade}</Grade></AvgGrade>}
          </EducationCard>
        ))}
      </CardWrapper>
    </Wrapper>
  )
}

export default CardsBlock
