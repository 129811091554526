import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled";
import {borderRadius, colors, spacing, transitions} from "../Styles/variables";
import {css} from "@emotion/react";
import {ScrollScene} from "scrollscene";

const cornerSize = '40px'
const ballSize = '40px'

const Wrapper = styled.div`
  padding: ${spacing.lg};
  background-color: ${colors.black};
  width: calc(50% - ${spacing.lg});
  margin-bottom: ${spacing.lg};
  color: ${colors.white};
  border-radius: ${borderRadius.lg};
  position: relative;
  opacity: 0;
  transform: translateX(-${spacing.lg});
  transition: ${transitions.page};
  transition-delay: .5s;
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  &::before {
    background-color: ${colors.grey};
    border-radius: 50%;
    width: ${ballSize};
    height: ${ballSize};
    position: absolute;
    right: calc(-${spacing.lg} - ${ballSize} / 2);
    top: 50%;
    transform: translateY(-50%);
    content: attr(data-number);
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.mediumGrey}
  }
  
  &::after {
    content: "";
    position: absolute;
    transform: rotate(45deg) translate(0, -70%);
    background-color: ${colors.black};
    width: ${cornerSize};
    height: ${cornerSize};
    top: 50%;
    right: 0;
    z-index: -1;
  }

  ${({even}) => !even && css`
    align-self: flex-end;
    transform: translateX(${spacing.lg});

    &::before {
      right: auto;
      left: calc(-${spacing.lg} - ${ballSize} / 2);
    }
    
    &::after {
      left: 0;
      transform: rotate(45deg) translate(-70%, 0);
    }
  `}
  
  &.in-view {
    opacity: .9;
    transform: translateX(0);
  }

  @media only screen and (max-width: 992px) {
    width: calc(100%);

    align-self: flex-end;
    transform: translateX(${spacing.lg});

    &::before {
      right: auto;
      left: calc(-${spacing.lg} - ${ballSize} / 2);
    }

    &::after {
      left: 0;
      transform: rotate(45deg) translate(-70%, 0);
    }
  }
`

const TimelineLeave = ({index, children}) => {
  const wrapper = useRef(null)

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

  return (
    <Wrapper data-number={index+1} ref={wrapper} even={index % 2 === 0}>
      {children}
    </Wrapper>
  )
}

export default TimelineLeave
