import React, {useEffect, useRef, useState} from 'react'
import styled from "@emotion/styled"
import {colors} from "../Styles/variables"
import { ScrollScene } from 'scrollscene'
import * as THREE from "three"
import debounce from "../../utils/debounce"
import isOnMobile from "../../isOnMobile";

const CanvasTarget = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: exclusion;
  
  @media print {
    display: none;
  }

  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
`

const Background = ({locationPath = {}}) => {
  const locationPathRef = useRef(locationPath)
  const wrapper = useRef(null)
  const canvasTarget = useRef(null)

  let camera, scene, renderer, mouseX, mouseY
  let previousScrollOffset = 0
  const homePath = '/'

  useEffect(() => {
    locationPathRef.current = locationPath
  },[locationPath])


  const generateThreeJs = () => {
    const gridWidth = 30;
    const gridHeight = 30;

    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(90, canvasTarget.current.offsetWidth / canvasTarget.current.offsetHeight, .1, 1000)

    mouseX = canvasTarget.current.offsetWidth / 2
    mouseY = canvasTarget.current.offsetHeight / 2

    camera.position.z = 0

    // Create a ambient light
    const hemisphereLight = new THREE.HemisphereLight( colors.glitchBlue, colors.glitchPink, .35 );
    hemisphereLight.rotateX(90 * Math.PI / 180)
    scene.add( hemisphereLight );

    // Set the renderer
    renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
    renderer.setSize(canvasTarget.current.offsetWidth, canvasTarget.current.offsetHeight)

    // Append the dom canvas to the wrapper
    canvasTarget.current.appendChild(renderer.domElement)

    // Initialize a cube
    const geometry = new THREE.BoxGeometry(.1, .1, .1)
    const material = new THREE.MeshPhongMaterial({color: 0xffffff})
    // Create the cube
    const cubesUpper = []
    let index = 0

    const light = new THREE.PointLight( 0xffffff, 1, 18 );
    light.position.set( -3, -12, -3 );
    scene.add( light );

    for(let y = 0; y < gridHeight; y += 1) {
      const yOffset = y - (gridHeight / 2)
      cubesUpper[index] = []
      for(let x = 0; x < gridWidth; x += 1) {
        const cube = new THREE.Mesh(geometry, material)
        cube.rotation.x = 45
        cube.rotation.y = 45

        cube.position.z = yOffset
        cube.position.x = x - (gridWidth / 2)
        cube.randomOffset = Math.floor(Math.random() * 100)
        cube.wiggleDirection = Math.floor(Math.random() * 2) === 1 ? 1 : -1

        // Add the cube to the scene
        scene.add(cube)

        cubesUpper[index].push(cube)
      }
      index++
    }

    const cubesLower = []

    const bottomOffsetY = -10

    for(let y = 0; y < gridHeight; y += 1) {
      const yOffset = y - (gridHeight / 2)
      cubesLower[index] = []
      for(let x = 0; x < gridWidth; x += 1) {
        const cube = new THREE.Mesh(geometry, material)
        cube.rotation.x = 45
        cube.rotation.y = 45

        cube.position.z = yOffset
        cube.position.x = x - (gridWidth / 2)
        cube.position.y = bottomOffsetY
        cube.wiggleDirection = Math.floor(Math.random() * 2) === 1 ? 1 : -1
        cube.randomOffset = Math.floor(Math.random() * 100)

        // Add the cube to the scene
        scene.add(cube)

        cubesLower[index].push(cube)
      }
      index++
    }
    const cameraWiggle = 0.05
    const cameraWiggleSpeed = 0.0001
    let currentWiggle = 0
    let cameraWiggleDown = false

    const animate = () => {
      requestAnimationFrame(animate);
      if(locationPathRef.current !== homePath && canvasTarget?.current?.style) {
        // 7 is the default blur value
        canvasTarget.current.style.filter = "blur(7px)"
      }

      const inYourFaceDegrade = Math.max(0, previousScrollOffset / 300 -1) * .5
      const inYourFace = inYourFaceDegrade <= .7 ? 1 - inYourFaceDegrade : .3

      if(canvasTarget?.current?.style) {
        canvasTarget.current.style.transform = `scale(${1 - (1 - inYourFace) / 4})`
        if(locationPathRef.current === homePath) {
          canvasTarget.current.style.opacity = 1 - (1 - inYourFace) / 3
          canvasTarget.current.style.filter = `blur(${(1 - inYourFace) * 10}px)`
        }
      }
      if(cameraWiggleDown) {
        currentWiggle += cameraWiggleSpeed
      } else {
        currentWiggle -= cameraWiggleSpeed
      }

      if(
        (cameraWiggleDown && currentWiggle >= cameraWiggle) ||
        (!cameraWiggleDown && currentWiggle <= -cameraWiggle)
      ) cameraWiggleDown = !cameraWiggleDown

      cubesUpper.forEach((cubeArray) => {
        cubeArray.forEach((cube) => {
          cube.position.y += ((currentWiggle * (cube.randomOffset / 100)- (currentWiggle * (cube.randomOffset / 100)) / 2) * cube.wiggleDirection) * inYourFace
        })
      })

      camera.position.y = (Math.sin(mouseY) * (inYourFace / 2)) - 5 + currentWiggle
      camera.position.x =  (Math.sin(-mouseX)) * (inYourFace / 2)

      if(previousScrollOffset !== window.scrollY) {
        cubesLower.forEach((cubeArray) => {
          cubeArray.forEach((cube) => {
            cube.position.y = bottomOffsetY + ((cube.randomOffset * 30) * window.scrollY) / (100 * canvasTarget.current.offsetHeight)
          })
        })
      }

      previousScrollOffset = window.scrollY

      renderer.render(scene, camera)
    };

    animate();
  }

  const onWindowResize = debounce(() => {
    camera.aspect = canvasTarget.current.offsetWidth / canvasTarget.current.offsetHeight
    camera.updateProjectionMatrix()
    renderer.setSize(canvasTarget.current.offsetWidth, canvasTarget.current.offsetHeight)
  }, 100)

  const updateMousePos = (e) => {
    mouseX = e.clientX / canvasTarget.current.offsetWidth
    mouseY = e.clientY / canvasTarget.current.offsetHeight
  }

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })

    generateThreeJs()

    window.addEventListener('resize', onWindowResize, false)
    if(!isOnMobile()) {
      document.addEventListener("mousemove", updateMousePos, false);
    }
  }, [wrapper])

  return(
    <>
      <div ref={wrapper}>
        <CanvasTarget ref={canvasTarget}/>
      </div>
    </>
  )
}

export default Background
