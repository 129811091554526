export const colors = {
  trueBlack: "#000",
  white: "#fff",
  lightestGrey: "#efefef",
  lightGrey: "#dfdfdf",
  mediumGrey: '#888',
  grey: "#222",
  black: "#111",
  glitchPink: "#ff00c1",
  glitchBlue: "#00fff9",
  success: 'rgb(86,166,86)',
  danger: 'rgb(206,1,1)',
}

export const spacing = {
  sm: "4px",
  md: "15px",
  lg: "50px",
  xl: '100px'
}

export const borderRadius = {
  base: '5px',
  lg: '15px',
}

export const button = {
  paddingTop: '16px',
  paddingSide: '32px',
  fontSize: '16px',
}

export const fonts = {
  action: "'Anton', sans-serif",
}

export const letterSpacing = {
  action: '.25em'
}

export const pageTransitionTimeMs = 1000;

export const transitions = {
  base: ".15s ease",
  slow: '.5s ease',
  page: `${pageTransitionTimeMs / 1000}s ease`,
  pageSlow: "1s ease",
}

export const parallaxSpeed = {
  sm: 5,
  md: 10,
  lg: 20,
}
