import React, {useEffect, useRef, useState} from 'react'
import styled from "@emotion/styled";
import {borderRadius, spacing, transitions} from "../Styles/variables";
import {ScrollScene} from "scrollscene";
import {css} from "@emotion/react";
import {Parallax} from "react-scroll-parallax";
import debounce from "../../utils/debounce";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: calc(${spacing.xl} * 2) 0;
  
  ${({reverse}) => reverse && css`
    flex-direction: row-reverse;
  `}
  
  &.in-view .content-wrapper {
    opacity: 1;
    transform: translateY(0);
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin: ${spacing.lg} 0;
  }
`

const ContentWrapper = styled.div`
  width: 50%;
  padding: ${spacing.md};
  transition: ${transitions.page};
  opacity: 0;
  transform: translateY(${spacing.lg});
  
  ${({delay}) => delay && css`transition-delay: ${delay};`};

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: ${spacing.md} 0;
  }
`

const TextWrapper = styled.div`
  max-width: 450px;

  @media only screen and (max-width: 992px) {
    max-width: 100%;
  }
`

const Image = styled.img`
  max-width: 100%;

  @media only screen and (max-width: 992px) {
    border-radius: ${borderRadius.base};
    width: 100%;
  }
`

const parallaxSpeed = [40, -40]

const TextImage = ({title, text, image, reverse}) => {
  const wrapper = useRef(null);
  const [parallaxValues, setParallaxValues] = useState(window.innerWidth <= 991 ? [0, 0] : parallaxSpeed)

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

useEffect(() => {
  window.addEventListener('resize', onWindowResize, false)

  return () => window.removeEventListener('resize', onWindowResize, false)
}, [])


  const onWindowResize = debounce(() => {
    const isMobileRes = window.innerWidth <= 991

    setParallaxValues(isMobileRes ? [0, 0] : parallaxSpeed)
  }, 100)

  return (
    <Wrapper ref={wrapper} reverse={reverse}>
      <ContentWrapper className="content-wrapper" delay=".75s">
        <TextWrapper>
          <Parallax translateY={parallaxValues}>
            <h2>{title}</h2>
            <p>{text}</p>
          </Parallax>
        </TextWrapper>
      </ContentWrapper>
      <ContentWrapper className="content-wrapper" delay=".5s">
        <Image src={image} alt={title} />
      </ContentWrapper>
    </Wrapper>
  )
}

export default TextImage
