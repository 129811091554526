import React, {useRef} from "react"
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/Home";
import Resume from "./pages/Resume";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {pageTransitionTimeMs, transitions} from "./components/Styles/variables";
import Background from "./components/background";
import Menu from "./components/Menu";
import styled from "@emotion/styled";
import WorkItem from "./pages/Work/Item";
import Work from "./pages/Work";
import {ParallaxProvider} from "react-scroll-parallax";
import NotFound from "./pages/NotFound";

const Content = styled.main`
  transition: ${transitions.slow};
`

const App = () => {
  const location = useLocation()
  const contentRef = useRef(null)

  return (
    <>
      <Background locationPath={location.pathname}/>
      <Menu />
      <ParallaxProvider>
        <Content id="main" ref={contentRef}>
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              timeout={pageTransitionTimeMs}
              className="transition"
            >
              <Routes location={location}>
                <Route exact path="/" element={<Home />}/>
                {/*<Route exact path="/resume" element={<Resume />}/>*/}
                <Route path="/projects" element={<Work />}/>
                <Route path="/projects/:path" element={<WorkItem />}/>

                <Route path="*" element={<NotFound />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </Content>
      </ParallaxProvider>
    </>
  );
}

export default App;
