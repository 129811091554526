import styled from "@emotion/styled";
import {spacing} from "./variables";

export const containerWidth = '1200px';

export const Container = styled.div`
  max-width: ${containerWidth};
  width: 100%;
  margin: 0 auto;
  padding: ${spacing.md};
`;