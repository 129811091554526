import styled from "@emotion/styled";
import {pageTransitionTimeMs, transitions} from "./variables";

export const PageWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: ${transitions.page};

  &.exit.exit-active {
    transform: translateY(20vh);
    opacity: 0;
    transition: ${pageTransitionTimeMs/3}ms;
  }
  
  &.enter{
    transition-delay: .5s;
    opacity: 0;
    transform: translateY(-20vh);
    
    &.enter-active {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  &.show {
    opacity: 1;
    overflow: visible;
  }
`
