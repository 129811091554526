import React from 'react'
import styled from "@emotion/styled";
import {spacing} from "../components/Styles/variables";
import Button from "../components/Button";
import {PageWrapper} from "../components/Styles/pageWrapper";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: ${spacing.md};
`

const Title = styled.h1`
    margin: 0;
`

const NotFound = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <Title>Page not found</Title>
        <p>Sorry, we were not able to find the page that you requested.</p>
        <Button isLink text="Go to the homepage" to={"/"}/>
      </Wrapper>
    </PageWrapper>
  )
}

export default NotFound