import React from 'react';
import styled from "@emotion/styled";
import {Container} from "../components/Styles/containers";
import {colors, pageTransitionTimeMs, spacing, transitions} from "../components/Styles/variables";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import data from '../components/Resume/resumeContent'
import Impression from "../components/Resume/Impression";
import CardsBlock from "../components/Resume/CardsBlock";
import {Divider, ResumeTitleBig} from "../components/Styles/Resume";
import Skills from "../components/Resume/Skills";
import Traits from "../components/Resume/Traits";
import {faEarthEurope} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {animateScroll} from "react-scroll";
import Button from "../components/Button";
import {PageWrapper} from "../components/Styles/pageWrapper";
import {FormWrapper} from "../components/Styles/input";
import ContactForm from "../components/ContactForm";

const GeneralInformation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

const GeneralInformationItem = styled.div`
  display: flex;
  padding: ${spacing.md};
  align-items: center;
  opacity: .75;
  color: ${colors.white};
  text-decoration: none;
  transition: ${transitions.base};

  @media print {
    color: #222;
  }
  
  &[href]:hover {
    opacity: 1;
  }
`

const LanguageWrapper = styled.div`
  display: flex;
  opacity: .75;
  align-items: center;
  margin-left: ${spacing.md};

  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`

const LanguageItem = styled.div`
  margin-right: ${spacing.md};
  
  &:last-child {
    margin-right: 0;
  }
`;

const LanguageItemTitle = styled.span`
  font-weight: bold;
  display: block;
`

const LanguageItemLevel = styled.small`
  display: block;
  opacity: .75;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: ${spacing.md};
`

const PrintButton = styled(Button)`
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

const PrintButtonWrapper = styled.div`
  position: fixed;
  padding: ${spacing.md};
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  mix-blend-mode: difference;

  @media print {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spacing.md};
  
  @media print {
    display: none;
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`

const StyledButton = styled(Button)`
  display: block;
  margin-bottom: calc(${spacing.lg} * 2);

  @media only screen and (max-width: 992px) {
    margin-bottom: ${spacing.md};
    
    &:nth-child(even) {
      margin-bottom: ${spacing.lg};
    }
  }  
`;

const Resume = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    animateScroll.scrollToTop({
      duration: pageTransitionTimeMs,
      smooth: 'easeInOutQuart'
    })

    navigate('/')
  }

  return (
    <PageWrapper>
      <Container>
        <ResumeTitleBig data-text={data.title}>{data.title}</ResumeTitleBig>
        <Divider/>
        <GeneralInformation>
          {data.header.map(({icon, text, aHref}) => (
            <GeneralInformationItem key={text} as={Boolean(aHref) ? 'a' : 'div'} href={aHref}>
              <StyledFontAwesomeIcon icon={icon}/>
              <div key={text}>{text}</div>
            </GeneralInformationItem>
          ))}
        </GeneralInformation>
        <LanguageWrapper>
          <StyledFontAwesomeIcon icon={faEarthEurope}/>
          {data.languages.map(({title, level}) => (
            <LanguageItem key={title}>
              <LanguageItemTitle>{title}</LanguageItemTitle>
              <LanguageItemLevel>{level}</LanguageItemLevel>
            </LanguageItem>
          ))}
        </LanguageWrapper>
        <Impression />
        <CardsBlock title={data.work.title} items={data.work.items}/>
        <CardsBlock title={data.education.title} items={data.education.items}/>
        <Skills />
        <Traits />
        <ButtonWrapper>
          <StyledButton onClick={navigateHome} text="Take me back to the homepage" />
        </ButtonWrapper>
      </Container>
      <FormWrapper>
        <ContactForm />
      </FormWrapper>
      <PrintButtonWrapper>
        <PrintButton onClick={() => window.print()} text="Save for print"/>
      </PrintButtonWrapper>
    </PageWrapper>
  )
}

export default Resume
