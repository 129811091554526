import styled from "@emotion/styled";
import {colors, spacing, transitions} from "./variables";
import {css} from "@emotion/react";

export const Alert = styled.div`
  position: fixed;
  top: ${spacing.lg};
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 999;
  padding: ${spacing.md} ${spacing.lg};
  background-color: ${colors.success};
  border-radius: 999px;
  font-weight: 800;
  opacity: 0;
  transition: ${transitions.base};
  pointer-events: none;
  
  ${({show}) => show && css`
    opacity: 1;
    transform: translate(-50%, 0);
  `}
  
  ${({danger}) => danger && css`
    background-color: ${colors.danger};
  `};

  @media only screen and (max-width: 992px) {
    width: calc(100% - ${spacing.md} * 2);
    text-align: center;
  }
`