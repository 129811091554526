import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled";
import {Divider, ResumeTitle} from "../Styles/Resume";
import data from "./resumeContent";
import {spacing} from "../Styles/variables";
import {ScrollScene} from "scrollscene";
import {Pill} from "../Styles/pill";

const Wrapper = styled.div`
  margin: calc(${spacing.lg} * 2) 0;
  
  @media print {
    margin-bottom: 0;
  }
`

const PillWrapper = styled.div`
  margin-top: ${spacing.lg};

  @media only screen and (max-width: 992px) { text-align: center; }

  &.in-view .skill__pill{
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  @media print {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const Skills = () => {
  const pageWrapper = useRef(null)
  const skillWrapper = useRef(null)

  useEffect(() => {
    if(Boolean(skillWrapper.current) && Boolean(pageWrapper.current)) {
      new ScrollScene({
        triggerElement: pageWrapper.current,

        toggle: {
          element: skillWrapper.current,
          className: "in-view"
        },
      })
    }
  }, [])

  return (
    <Wrapper ref={pageWrapper}>
      <ResumeTitle data-text={data.skills.title}>{data.skills.title}</ResumeTitle>
      <Divider />
      <PillWrapper ref={skillWrapper}>
        {data.skills.items.map((skill, index) => (
          <Pill className="skill__pill" key={skill} index={index}>{skill}</Pill>
        ))}
      </PillWrapper>
    </Wrapper>
  )
}

export default Skills
