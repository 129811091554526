import React from 'react'
import styled from "@emotion/styled";
import glitch from "../Styles/glitch";
import {button, colors, fonts, letterSpacing, transitions} from "../Styles/variables";
import {css} from "@emotion/react";
import {Link} from "react-router-dom";

export const buttonStyle = css`
  position: relative;
  border: 1px solid ${colors.white};
  padding: ${button.paddingTop} ${button.paddingSide};
  color: ${colors.white};
  font-weight: 400;
  letter-spacing: ${letterSpacing.action};
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  text-transform: uppercase;
  box-shadow:  0 0 0 0 ${colors.glitchBlue}, 0 0 0 0 ${colors.glitchPink};
  transition: ${transitions.base};
  font-size: ${button.fontSize};
  font-family: ${fonts.action};
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  
  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }

  ${({full}) => full && 'width: 100%;'}

  &::after,
  &::before {
    padding: ${button.paddingTop} 0;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 3px black, 0 0 0 4px white;
    
    ${glitch}
  }
`

const StyledButton = styled.button`
  ${buttonStyle}
`

const StyledLink = styled(Link)`
  ${buttonStyle};
  display: inline-block;
`

const StyledA = styled.a`
  ${buttonStyle};
  display: inline-block;
`

const Button = ({text, isLink, isA, ...props}) => (
  <>
    {Boolean(isLink) && <StyledLink data-text={text} {...props}>{text}</StyledLink>}
    {Boolean(isA) && <StyledA data-text={text} {...props}>{text}</StyledA>}
    {(!isLink && !isA) && <StyledButton data-text={text} {...props}>{text}</StyledButton>}
  </>
)


export default Button
