import React, {useEffect, useRef, useState} from 'react'
import styled from "@emotion/styled";
import {colors, pageTransitionTimeMs, spacing, transitions} from "../Styles/variables";
import {css} from "@emotion/react";
import {Container} from "../Styles/containers";
import {useNavigate} from "react-router-dom";
import glitch from "../Styles/glitch";
import {animateScroll} from "react-scroll";
import Modal from "../Modal";
import OpenResumeBlock from "../OpenResumeBlock";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  mix-blend-mode: difference;
`

const MenuWrapper = styled.div`
  position: relative;
  display: inline-flex;
  padding: ${spacing.md} 0;
  transform: translateY(0);
  transition: ${transitions.page};

  @media only screen and (min-width: 992px) {
    &:hover {
      .button__wrapper {
        max-width: 150px;
        max-height: 36px;
        background-color: transparent;
        margin-right: ${spacing.md};
        padding: 0 ${spacing.md};
        pointer-events: all;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    position: absolute;
    z-index: 999;
    top: ${spacing.md};
    left: 50%;
    transform: translateX(-50%);
    
    &.open {
      .button__wrapper {
        max-width: 150px;
        max-height: 36px;
        background-color: transparent;
        margin-right: ${spacing.md};
        padding: 0 ${spacing.md};
        pointer-events: all;
      }
    }
  }
`

const menuButtonStyled = css`
  color: ${colors.white};
  font-size: 2rem;
  text-decoration: none;
  overflow: visible;
  text-transform: uppercase;
  font-family: 'Anton', sans-serif;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  display: block;
  transition: ${transitions.base};

  &:hover:not([disabled]) {
    ${glitch};
  }

  &[disabled] {
    cursor: default;
    opacity: .5;
  }

  @media only screen and (max-width: 992px) {
    font-size: 1.5rem;
  }
`

const menuTransitionSpeed = .125

const ButtonWrapper = styled.div`
  overflow: hidden;
  position: relative;
  max-height: ${spacing.sm};
  background-color: ${colors.white};
  margin-right: ${spacing.sm};
  max-width: ${spacing.md};
  ${({delay}) => css`transition: max-width ${menuTransitionSpeed}s ${delay}s, padding ${menuTransitionSpeed}s ${delay}s, max-height ${menuTransitionSpeed}s ${delay + menuTransitionSpeed}s, background-color ${menuTransitionSpeed}s ${delay + menuTransitionSpeed}s, margin-right ${menuTransitionSpeed}s ${delay}s;`}
  
  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 992px) {
    pointer-events: none;
  }
`

const MenuItemButton = styled.button`
  margin-top: -4px;
  ${menuButtonStyled}
`

const Menu = () => {
  const menu = useRef(null)
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const switchPage = (newUrl = '/') => {
    animateScroll.scrollToTop({
      duration: pageTransitionTimeMs,
      smooth: 'easeInOutQuart'
    })

    navigate(newUrl)
  }

  const handleClick = (e) => {
    if (menu.current && !menu.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  const openResumeModal = () => {
    console.log("Calling after event")
    setModalIsOpen(true)
  }

  const menuItems = [
    {
      label: 'HOME',
      href: '/'
    },
    {
      label: 'PROJECTS',
      href: '/projects'
    },
    {
      label: 'RESUME',
      clickHandler: () => setModalIsOpen(true)
    }
  ]

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    addEventListener("openResumeModal", openResumeModal)

    return () => {
      document.removeEventListener("mousedown", handleClick);
      // removeEventListener("openResumeModel", openResumeModal)
    };
  }, [])

  return (
    <>
      <MenuContainer>
        <Container>
          <MenuWrapper ref={menu} className={isOpen ? 'open' : ''} onClick={() => setIsOpen((oldVal) => !oldVal)}>
            {menuItems.map(({label, href, clickHandler}, index, array) => (
              <ButtonWrapper delay={index * .05} className="button__wrapper" key={label}>
                {href && <MenuItemButton className={isOpen ? 'clickable': ''} onClick={() => switchPage(href)} data-text={label}>{label}</MenuItemButton>}
                {clickHandler && <MenuItemButton className={isOpen ? 'clickable': ''} onClick={clickHandler} data-text={label}>{label}</MenuItemButton>}
              </ButtonWrapper>
            ))}
          </MenuWrapper>
        </Container>
      </MenuContainer>
      <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
        <OpenResumeBlock onClickHandler={() => setModalIsOpen(false)} />
      </Modal>
    </>
  )
}

export default Menu
