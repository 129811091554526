import React from 'react'
import {useParallax} from "react-scroll-parallax";
import {parallaxSpeed, spacing} from "../Styles/variables";
import styled from "@emotion/styled";
import {css} from "@emotion/react";

export const workContentWidth = ' max(30%, 300px)'

const TeaserWrapper = styled.div`
  flex: calc(100% - ${workContentWidth}) 0;
  padding-bottom: 60%;
  position: relative;
  height: 0;
  
  ${({primColor, secColor}) => css`
    background: linear-gradient(45deg, ${primColor} 0%, ${secColor} 100%);
  `}
`

const ParaWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-start;
`

const PrimImage = styled.img`
  position: relative;
  margin: ${spacing.xl} -${spacing.xl} 0 0;
  z-index: 1;
`

const SecImage = styled.img`
  margin: ${-spacing.xl} ${spacing.xl} 0 0;
`

const Teaser = ({primaryImage, secondaryImage, primaryColor, secondaryColor}) => {

  const primParallax = useParallax({
    speed: -parallaxSpeed.sm,
  });

  const secParallax = useParallax({
    speed: -parallaxSpeed.md,
  });

  return (
    <TeaserWrapper primColor={primaryColor} secColor={secondaryColor}>
      <ParaWrapper>
        <PrimImage ref={primParallax.ref} src={primaryImage}/>
        <SecImage ref={secParallax.ref} src={secondaryImage}/>
      </ParaWrapper>
    </TeaserWrapper>
  )

}

export default Teaser

// TODO ADD PROPTYPES