import React from 'react'
import styled from "@emotion/styled";
import {spacing} from "../../components/Styles/variables";
import {Container} from "../../components/Styles/containers";
import workContent from "../../workContent";
import WorkItem from "../../components/WorkItem";
import {Glitch} from "../../components/Landing";
import {PageWrapper} from "../../components/Styles/pageWrapper";
import {FormWrapper} from "../../components/Styles/input";
import ContactForm from "../../components/ContactForm";

const PageHeader = styled.div`
  padding: max(17.5vh, ${spacing.lg}) 0;
`

const SubTitle = styled.span`
  text-align: center;
  width: 100%;
  display: block;
  letter-spacing: 10px;
`

const Title = styled(Glitch)`
  text-transform: uppercase;
  margin-bottom: ${spacing.md};

`

const Work = () => {
  return (
    <PageWrapper>
      <Container>
        <PageHeader>
          <Title data-text={"PROJECTS"}>PROJECTS</Title>
          <SubTitle>
            BY MIDAS MINNEGAL
          </SubTitle>
        </PageHeader>
        {workContent.map((item, index) => <WorkItem key={index} even={index % 2 === 1} {...item}/>)}
      </Container>
      <FormWrapper>
        <ContactForm />
      </FormWrapper>
    </PageWrapper>
  )
}

export default Work
