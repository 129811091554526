import styled from "@emotion/styled";
import {borderRadius, colors, spacing, transitions} from "./variables";
import {css} from "@emotion/react";


export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: ${spacing.lg} 0 ${spacing.lg} 0;

`

export const InputLabel = styled.label`
  position: absolute;
  top: 0;
  left: ${spacing.md};
  transform: translateY(${spacing.md});
  color: ${colors.mediumGrey};
  transition: ${transitions.base};
  pointer-events: none;
  text-transform: capitalize;
`

const inputIconSize = '24px';

export const InputIcon = styled.div`
  &::after,
  &::before {
    content: "✓";
    width: ${inputIconSize};
    height: ${inputIconSize};
    border: 2px solid ${colors.success};
    color: ${colors.success};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    font-size: .9rem;
    opacity: 0;
    transition: ${transitions.base};
    position: absolute;
    top: 50%;
    right: ${spacing.md};
    z-index: 1;
  }
    
    &::before {
      content: "⤫";
      border: 2px solid ${colors.danger};
      color: ${colors.danger};
    }
  }
`

const inputStyle = css`
  width: 100%;
  padding: ${spacing.md} calc(${inputIconSize} + (${spacing.md} * 2)) ${spacing.md} ${spacing.md};
  background-color: ${colors.grey};
  border: 0;
  outline: 0;
  border-radius: ${borderRadius.base};
  color: ${colors.white};
  font-size: 1rem;
  
  &::after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: 1;
  }

  &:not(:placeholder-shown) ~ label {
    transform: translateY(-100%);
    top: -${spacing.sm};
  }

  &[required] ~ label::after {content: " *";}
  
  &:valid + div::after {
    transform: translateY(-50%);
    opacity: 1;
  }

  &:invalid:not(:placeholder-shown) + div::before {
    transform: translateY(-50%);
    opacity: 1;
  }

`

export const Input = styled.input`
  ${inputStyle};
`

export const TextArea = styled.textarea`
  ${inputStyle};
  font-family: inherit;
  resize: none;
`

export const FormWrapper = styled.div`
  background-color: ${colors.black};
  padding: ${spacing.lg};
  margin: ${spacing.lg} auto 0;

  @media print {
    display: none;
  }

  @media only screen and (max-width: 992px) {
    padding: ${spacing.md};
  }
`