import React from 'react'
import styled from '@emotion/styled'
import {keyframes} from "@emotion/react";
import {transitions} from "./Styles/variables";

const IconAnimation = keyframes`
  0%{
    transform: translateY(5px);
  }
  75%{
    transform: translateY(5px);
  }
  85%{
    transform: translateY(20px);
  }
  90%{
    transform: translateY(5px);
  }
  95%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(5px);
  } 
`

const Icon = styled.svg`
  position: relative;
  z-index: 1;
  overflow: visible;
  transition: .15s;
  
  g {
    animation: ${IconAnimation} 3s infinite;
    transition: ${transitions.base};
  }
  
  .cls-1 {
    stroke: #fff;
    stroke-width: 3px;
    fill: none;
    fill-rule: evenodd;
  }
  
  .cls-2 {
    stroke: none;
    stroke-width: 3px;
    fill: white;
    fill-rule: evenodd;
  }
`

const ScrollIcon = () => (
  <Icon
       width="48" height="48" viewBox="0 0 128 128">
    <path
      d="M64.000,-0.000 C77.807,-0.000 89.000,11.193 89.000,25.000 L89.000,75.000 C89.000,88.807 77.807,100.000 64.000,100.000 C50.193,100.000 39.000,88.807 39.000,75.000 L39.000,25.000 C39.000,11.193 50.193,-0.000 64.000,-0.000 Z"
      className="cls-1"/>
    <path
      d="M64.000,13.000 C66.761,13.000 69.000,15.239 69.000,18.000 L69.000,28.000 C69.000,30.761 66.761,33.000 64.000,33.000 C61.239,33.000 59.000,30.761 59.000,28.000 L59.000,18.000 C59.000,15.239 61.239,13.000 64.000,13.000 Z"
      className="cls-1"/>
    <g>
      <path
        d="M88.824,109.756 C88.421,109.052 87.521,108.806 86.812,109.206 L63.729,122.245 C63.021,122.646 62.773,123.540 63.176,124.244 C63.579,124.948 64.479,125.194 65.188,124.794 L88.271,111.755 C88.979,111.354 89.227,110.460 88.824,109.756 Z"
        className="cls-2"/>
      <path
        d="M39.460,109.586 C39.870,108.866 40.785,108.614 41.506,109.024 L64.977,122.368 C65.697,122.778 65.949,123.694 65.540,124.414 C65.130,125.134 64.215,125.386 63.494,124.976 L40.023,111.632 C39.303,111.222 39.051,110.306 39.460,109.586 Z"
        className="cls-2"/>
    </g>
  </Icon>
)


export default ScrollIcon
