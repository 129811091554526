import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import workContent, {contentTypes} from "../../workContent";
import styled from "@emotion/styled";
import {
  borderRadius,
  colors,
  fonts,
  pageTransitionTimeMs,
  spacing,
  transitions
} from "../../components/Styles/variables";
import {Glitch} from "../../components/Landing";
import {Container} from "../../components/Styles/containers";
import TextImage from "../../components/ContentBlock/TextImage";
import TextCenter from "../../components/ContentBlock/TextCenter";
import Button from "../../components/Button";
import {animateScroll} from "react-scroll";
import {css} from "@emotion/react";
import Timeline from "../../components/ContentBlock/Timeline";
import {PageWrapper} from "../../components/Styles/pageWrapper";
import ContactForm from "../../components/ContactForm";

const Title = styled(Glitch)`
  text-transform: uppercase;
  word-wrap:break-word;
`

const HeaderWrapper = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${spacing.md};

  @media only screen and (max-width: 992px) {
    height: auto;
    margin: ${spacing.lg} 0 ${spacing.xl};
  }
`

const Intro = styled.p`
  max-width: 600px;
  text-align: center;
  margin: 0;
  line-height: 1.5;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`
const imageWidth = '800px'

const Image = styled.img`
  display: block;
  width: ${imageWidth};
  border-top-right-radius: ${borderRadius.lg};
  border-bottom-right-radius: ${borderRadius.lg};

  @media only screen and (max-width: 992px) {
    display: none;
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.xl};

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const Details = styled.div`
  background-color: ${colors.black};
  border-top-left-radius: ${borderRadius.lg};
  border-bottom-left-radius: ${borderRadius.lg};
  width: calc(100% - ${imageWidth});
  padding: ${spacing.lg};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    width: 100%;
    border-radius: ${borderRadius.lg};
    padding: ${spacing.md};
  }
`

const DetailsTitle = styled.span`
  letter-spacing: 5px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  width: 100%;
  padding-bottom: ${spacing.md};
  border-bottom: 1px solid ${colors.mediumGrey};
`

const DetailsSection = styled.div`
  margin: ${spacing.lg} 0;

  @media only screen and (max-width: 992px) {
    margin: calc(${spacing.md} * 2) 0;
  }
`

const DetailsLabel = styled.span`
  color: ${colors.mediumGrey};
  margin-bottom: ${spacing.sm};
  display: block;
  font-family: 'Anton', sans-serif;
`

const ProjectNavigationWrapper = styled.div`
  margin: ${spacing.xl} 0 ${spacing.lg};
  display: flex;
  border-radius: ${borderRadius.lg};
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    border-radius: 0;
  }
;`

const nextProjectSize = '60%';

const NextProjectWrapper = styled.button`
  width: ${nextProjectSize};
  padding: 0;
  margin: 0;
  background-color: ${colors.trueBlack};
  outline: 0;
  border: 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover,
  &:focus {
    .next-pro-img {
      opacity: 1;
      transform: scale(1.015);
    }
    
    .default-text {
      opacity: 0;
      transform: translateY(${spacing.lg});
    }
    
    .hover-text {
      opacity: 1;
      transform: translateY(0);
    }

    @media only screen and (max-width: 992px) {
      .default-text {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
    border-radius: ${borderRadius.lg};
    padding: ${spacing.md};
    margin-bottom: ${spacing.xl};
    display: flex;
    flex-direction: column;
  }
`

const NextProjectImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: .5;
  transition: ${transitions.page};
  transform: scale(1);
  object-fit: cover;

  @media only screen and (max-width: 992px) {
    opacity: .75;
    position: absolute;
    left: 0;
    top: 0;
    
    &:focus {
      opacity: 1;
    }
  }
`

const TeaserText = styled.span`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${spacing.lg};
  font-family: ${fonts.action};
  font-size: 24px;
  transition: ${transitions.base};
  text-shadow: 3px 3px 0 #000;

  &.default-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  &.hover-text {
    opacity: 0;
    transform: translateY(-${spacing.lg});
  }

  @media only screen and (max-width: 992px) {
    position: relative;
    margin: ${spacing.sm} 0;
    padding: 0;
    display: block;

    &.default-text,
    &.hover-text {
      opacity: 1;
      transform: translateY(0);
    }
    
    &:first-of-type {
      margin-top: ${spacing.xl};
    }
    
    &:last-of-type {
      margin-bottom: ${spacing.xl};
    }
  }
`

const ContactWrapper = styled.div`
  padding: ${spacing.lg};
  width: calc(100% - ${nextProjectSize});
  background-color: ${colors.black};
  transition: ${transitions.page};
  
  ${({sending}) => sending && css`
    pointer-events: none;
    opacity: .5;
  `};

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: ${spacing.md};
    border-radius: ${borderRadius.lg}
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  margin-bottom: ${spacing.xl};

  @media only screen and (max-width: 992px) {
    margin-bottom: calc(${spacing.lg} - ${spacing.md});
  }
`

const StyledA = styled.a`
  color: ${colors.white};
  text-decoration: none;
`

const Index = () => {
  const params = useParams()
  const {path} = params
  const navigate = useNavigate()

  const indexOfProject = workContent.findIndex(({url}) => url === path)
  const nextIndexOfProject = indexOfProject + 1 < workContent.length ? indexOfProject + 1 : 0
  const {name, introduction, type, teaser, buildWith, client, content, projectUrl} = workContent[indexOfProject]
  const nextProject = workContent[nextIndexOfProject]

  if(!name) return

  const switchPage = (newUrl = '/') => {
    animateScroll.scrollToTop({
      duration: pageTransitionTimeMs,
      smooth: 'easeInOutQuart'
    })

    navigate(newUrl)
  }

  const details = [
    {
      label: 'Project Type',
      value: {value: type}
    },
    {
      label: 'Stack Used',
      value: {value: buildWith.join(', ')}
    },
    {
      label: 'commissioned By',
      value: client
    },
  ]


  return (
    <PageWrapper>
      <Container>
        <HeaderWrapper>
          <Title data-text={name}>{name}</Title>
          <Intro>{introduction}</Intro>
        </HeaderWrapper>
        <DetailsWrapper>
          <Details>
            <div>
              <DetailsTitle>
                Project Details
              </DetailsTitle>
              {details.map(({label, value}) => (
                <DetailsSection key={label}>
                  <DetailsLabel>{label}</DetailsLabel>
                  <div>
                    {!value.url && value.value}
                    {value.url && <StyledA href={value.url} rel="nofollow" target="_blank">{value.value}</StyledA>}
                  </div>
                </DetailsSection>
              ))}
            </div>
            {projectUrl && (<Button text="Visit site" rel="nofollow" isA={true} href={projectUrl} target="_blank" />)}
          </Details>
          <Image src={teaser} alt={name} />
        </DetailsWrapper>
        {content.map((contentBlock, index) => {
          if (contentBlock.type === contentTypes.TEXT_IMAGE) return <TextImage key={index} {...contentBlock} />
          if (contentBlock.type === contentTypes.TEXT_IMAGE_REVERSE) return <TextImage reverse={true} key={index} {...contentBlock} />
          if (contentBlock.type === contentTypes.TEXT_CENTER) return <TextCenter key={index} {...contentBlock} />
          if (contentBlock.type === contentTypes.TIMELINE) return <Timeline key={index} {...contentBlock} />
          return <></>
        })}

        <ProjectNavigationWrapper>
          <NextProjectWrapper onClick={() => switchPage(`/projects/${nextProject.url}`)}>
            <NextProjectImage className="next-pro-img" src={nextProject.teaser} alt={nextProject.name}/>
            <TeaserText className="default-text">VIEW NEXT PROJECT</TeaserText>
            <TeaserText className="hover-text">{nextProject.name}</TeaserText>
          </NextProjectWrapper>
          <ContactWrapper>
            <ContactForm />
          </ContactWrapper>
        </ProjectNavigationWrapper>
        <ButtonWrapper>
          <Button full text="Back to project overview" onClick={() => switchPage('/projects')} />
        </ButtonWrapper>
      </Container>
    </PageWrapper>
  )
}

export default Index
