import {css} from "@emotion/react";
import {colors, letterSpacing, spacing} from "./variables";
import styled from "@emotion/styled";

export const emphasizeTextStyle = css`
  position: relative;
  
  &::before, &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    color: transparent;
  }
  
  &::before{
    left: 1px;
    text-shadow: -1px 0 ${colors.glitchPink}, 1px 1px ${colors.glitchBlue};
  }

  &::after {
    left: -1px;
    text-shadow: 1px 0 ${colors.glitchBlue}, 1px 1px ${colors.glitchPink};
  }
`

export const emphasizeStyle = css`
  ${emphasizeTextStyle};

  &::before{
    content: "";
    left: 3px;
    box-shadow: -1px -1px 0 ${colors.glitchPink};
  }

  &::after {
    content: "";
    left: -3px;
    box-shadow: 1px 1px 0 ${colors.glitchBlue}, 2px 2px ${colors.glitchPink};
  }
`

export const Divider = styled.div`
  width: 100%;
  background-color: ${colors.white};
  background: linear-gradient(90deg, ${colors.glitchPink}, ${colors.glitchBlue});
  height: 2px;
  opacity: .75;
`;

export const ResumeTitle = styled.h1`
  color: ${colors.white};
  font-size: 2.5em;
  letter-spacing: ${letterSpacing.action};
  max-width: 100%;
  z-index: 1;

  @media print {
    color: #222;
  }

  @media only screen and (max-width: 992px) {
    text-align: center;
  }

  ${emphasizeTextStyle}
`

export const ResumeTitleBig = styled(ResumeTitle)`
  text-align: center;
  padding-top: ${spacing.md};
  
  &::before,
  &::after {
    left: 3px;
    top: ${spacing.md};
  }
  
  &::after {left: -3px;}
  
  @media only screen and (min-width: 992px) { font-size: 4em; }
`