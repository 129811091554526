import React, {useEffect} from 'react'
import styled from "@emotion/styled";
import {borderRadius, colors, spacing, transitions} from "../Styles/variables";
import {css} from "@emotion/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

const OuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .25);
    backdrop-filter: blur(15px);
    opacity: 0;
    pointer-events: none;
    transition: ${transitions.slow};
    
    ${({isOpen}) => isOpen && css`
        opacity: 1;
        pointer-events: all;
    `};

    @media only screen and (max-width: 992px) {align-items: flex-end;}
`

const ContentWrapper = styled.div`
    padding: ${spacing.lg};
    background-color: ${colors.grey};
    border-radius: ${borderRadius.lg};
    position: relative;
    z-index: 1;
    transition: ${transitions.slow};
    transform: scale(.5) translateY(${spacing.xl});

    @media only screen and (max-width: 992px) {
        padding: ${spacing.lg} ${spacing.md};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transform: translateY(${spacing.xl});
    }

    ${({isOpen}) => isOpen && css`
        transform: scale(1) translateY(0);

        @media only screen and (max-width: 992px) {
            transform: translateY(0);
        }
    `};
`

const ClickCatcher = styled.button`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
`

const CloseIconButton = styled.button`
    position: absolute;
    right: calc(${spacing.md} + ${spacing.sm});
    top: calc(${spacing.md} + ${spacing.sm});
    background-color: transparent;
    outline: 0;
    border: 0;
    color: ${colors.white};
    font-size: 1rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
    
    .close-icon {
        transform: rotate(0deg);
        transition: ${transitions.base};
    }
    
    &:hover .close-icon {
        transform: rotate(90deg);
    }
`

const Modal = ({isOpen, setIsOpen = (isOpen) => null, children}) => {

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'auto'
    // document.getElementById('main').style.transform = isOpen ? 'scale(.95)' : 'scale(1)'
  }, [isOpen])

  const closeModal = () =>
    setIsOpen(false)

  return (
    <>
      <OuterWrapper isOpen={isOpen}>
        <ClickCatcher onClick={closeModal} />
        <ContentWrapper isOpen={isOpen}>
          <CloseIconButton onClick={closeModal}>
            <FontAwesomeIcon className="close-icon" size="lg" icon={faClose}/>
          </CloseIconButton>
          {children}
        </ContentWrapper>
      </OuterWrapper>
    </>
  )
}

export default Modal
