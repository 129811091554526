import React from 'react'
import Landing from "../components/Landing";
import AboutMe from "../components/AboutMe";
import StartAProject from "../components/StartAProject";
import {PageWrapper} from "../components/Styles/pageWrapper";
import ContactForm from "../components/ContactForm";
import {FormWrapper} from "../components/Styles/input";

const Home = () => {
  return (
    <PageWrapper>
      <Landing />
      <AboutMe />
      <StartAProject />
      <FormWrapper id="contact-me">
        <ContactForm />
      </FormWrapper>
    </PageWrapper>
  );
}

export default Home;
