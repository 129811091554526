import React, {useEffect, useRef, useState} from 'react';
import styled from "@emotion/styled";
import image1 from './images/photo1.jpg'
import image2 from './images/photo2.jpeg'
import image3 from './images/photo-3.jpg'
import image4 from './images/photo-4.jpg'
import {colors, spacing, transitions} from "../Styles/variables";
import {css, keyframes} from "@emotion/react";
import {ScrollScene} from "scrollscene";
import {emphasizeStyle} from "../Styles/Resume";
import {useSwipeable} from "react-swipeable";

const skew = 9

const GlitchWrapper = styled.div`
  transform: skew(-${skew}deg) scale(.8) translateX(0);
  margin: -${spacing.lg} 0;

  @media only screen and (max-width: 992px) { margin: 0; }

  ${emphasizeStyle};
  
  &::after, &::before {
    transform: skew(-${skew}deg) scale(.8) translateX(0);
    opacity: .25;
    
    @media only screen and (max-width: 992px) { transform: none; }
  }

  &::before {
    box-shadow: -3px -3px 0 ${colors.glitchPink};
    
    @media only screen and (max-width: 992px) {  content: none; }
  }
  
  &::after {
    box-shadow: 3px 3px 0 ${colors.glitchBlue}, 2px 2px ${colors.glitchPink};

    @media only screen and (max-width: 992px) { content: none; }
  }

  @media only screen and (max-width: 992px) { transform: none; }
  @media print {
    transform: skew(0deg) scale(.8) translateX(0);

    &::before,
    &::after {
      content: none;
    }
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  padding-bottom:100%;
  transform: skew(-${skew}deg) scale(.8) translateX(-${spacing.md});
  overflow: hidden;
  position: relative;
  mix-blend-mode: lighten;
  opacity: 0;
  transition: ${transitions.page};
  transition-delay: .5s;

  @media only screen and (max-width: 992px) {
    transform: none;
    margin-bottom: ${spacing.md};
  }

  @media print {
    opacity: 1;
    transform: none;
  }

    &.in-view {
    opacity: 1;
    transform: skew(-${skew}deg) scale(.8) translateX(0);

    @media only screen and (max-width: 992px) {
      transform: none;
    }
  }
`

const SliderContent = styled.div`
  transform: translate(-50%, -50%) skew(${skew}deg);
  position: absolute;
  width: 130%;
  height: 100%;
  left: 50%;
  top: 50%;

  @media print {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  
  @media only screen and (max-width: 992px) {
    transform: translate(-50%, -50%);
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(${({index, activeIndex}) => (index-activeIndex) * 100}%);
  transition: ${transitions.page};
`

const SliderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit:cover;
`

const animation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const SliderController = styled.button`
  height: ${spacing.sm};
  outline: 0;
  border: 0;
  transition: ${transitions.base};
  cursor: pointer;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  @media print {
    display: none;
  }


  &:hover {
    opacity: 1;
    transform: scaleY(400%);
    
    @media only screen and (max-width: 992px) { transform: none; }
  }
  
  &::before {
    content: "";
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.glitchPink};
  }
  
  &::after {
    transition-delay: .15s;
    transition: ${transitions.base};
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.glitchBlue};
    position: absolute;
    transform: translateX(0);

    @media only screen and (max-width: 992px) {
      transform: translateX(-100%);
    }
  }
  
  ${({index, activeIndex}) => (index === activeIndex) && css`
    opacity: 1;
    transform: scaleY(200%);

    @media only screen and (max-width: 992px) { transform: none; }

    &::after {
      transform: translateX(0);
      animation: ${animation} 4.9s linear; 
    }
  `}
  
  &:last-of-type {
    margin-right: 0;
  }
`

const SliderControllerWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 2;
  transform: translateX(-50%);
`

const images = [
  {
    src: image3,
    alt: 'Midas Minnegal biertje'
  },
  {
    src: image1,
    alt: 'Midas Minnegal'
  },
  {
    src: image2,
    alt: 'Midas Minnegal playing the guitar',
  },
  {
    src: image4,
    alt: 'Midas Minnegal smile',
  }
]

const Slider = () => {
  const sliderWrapper = useRef(null)
  const interval = useRef(null)
  const sliderTime = 5000
  const [sliderIndex, setSliderIndex] = useState(Math.floor(Math.random()*images.length))

  const handlers = useSwipeable({
    onSwipedLeft: () => goToSlide(sliderIndex + 1 >= images.length ? 0 : sliderIndex + 1),
    onSwipedRight: () => goToSlide(sliderIndex - 1 < 0 ? images.length - 1 : sliderIndex -1),
  });

  useEffect(() => {
    if(Boolean(sliderWrapper.current)) {
      new ScrollScene({
        triggerElement: sliderWrapper.current,

        toggle: {
          element: sliderWrapper.current,
          className: "in-view"
        },
      })
    }
  }, [])

  useEffect(() => {
    interval.current = setInterval(() => {
      setSliderIndex((oldIndex) => oldIndex + 1 >= images.length ? 0 : oldIndex + 1)
    }, sliderTime)

    return () => clearInterval(interval.current)
  }, [])

  const goToSlide = (index) => {
    if(index === sliderIndex) return;
    setSliderIndex(index);

    clearInterval(interval.current)

    interval.current = setInterval(() => {
      setSliderIndex((oldIndex) => oldIndex + 1 >= images.length ? 0 : oldIndex + 1)
    }, sliderTime)
  }

  return (
    <>
      <GlitchWrapper{...handlers}>
        <SliderWrapper  ref={sliderWrapper}>
          <SliderContent>
            {images.map(({src, alt}, index) => (
              <ImageWrapper key={alt} index={index} activeIndex={sliderIndex}>
                <SliderImage src={src} alt={alt} />
              </ImageWrapper>
            ))}
          </SliderContent>
          <SliderControllerWrapper>
            {images.map((image, index) => (
              <SliderController index={index} activeIndex={sliderIndex} onClick={() => goToSlide(index)}/>
            ))}
          </SliderControllerWrapper>
        </SliderWrapper>

      </GlitchWrapper>

    </>
  )
}

export default Slider;
