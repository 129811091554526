import React, {useEffect, useRef} from 'react';
import styled from "@emotion/styled";
import {borderRadius, colors, fonts, pageTransitionTimeMs, spacing, transitions} from "../Styles/variables";
import {css} from "@emotion/react";
import {ScrollScene} from "scrollscene";
import {animateScroll} from "react-scroll";
import {useNavigate} from "react-router-dom";
import {workContentWidth} from "./Teaser";


const Wrapper = styled.button`
  margin: ${spacing.xl} 0;
  color: ${colors.white};
  width: 100%;
  background-color: transparent;
  outline: 0;
  border: none;
  padding: 0;
  cursor: pointer;
  transform: translateY(${spacing.xl});
  opacity: 0;
  transition: ${transitions.page};

  @media only screen and (max-width: 992px) {
    margin: ${spacing.lg} 0;
  }
  
  &:hover,
  &:focus {
    .image {
      transform: scale(1.015) translate( -50%, -50%);

      @media only screen and (max-width: 992px) {
        transform: none;
      }
    }
    
    .more-text {
      &::after {
        transform: scaleX(1);
      }
    }
  }
  
  &.in-view {
    opacity: 1;
    transform: translateY(0);
    
    .content {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

const InnerWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  text-decoration: none;
  position: relative;
  text-align: left;

  ${({even}) => even && css`
    flex-direction: row-reverse;
    text-align: right;
  `}

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  flex: calc(100% - ${workContentWidth}) 0;
  padding-bottom: 60%;
  position: relative;
  height: 0;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    flex: 100%;
    padding-bottom: 0;
    height: auto;
  }
`

const Image = styled.img`
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50%);
  transition: ${transitions.pageSlow};

  @media only screen and (max-width: 992px) {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    height: inherit;
    width: 100%;
    border-radius: ${borderRadius.lg};
  }
`

const Content = styled.div`
  width: ${workContentWidth};
  padding: 0 ${spacing.lg};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 0;
    margin-top: ${spacing.md};
    text-align: center;
  }
`


const Tag = styled.span`
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
  transition: ${transitions.page};
  transition-delay: .25s;
  opacity: 0;
  transform: translateX(-${spacing.lg});
  
  &.even { transform: translateX(${spacing.lg}); }

  @media only screen and (max-width: 992px) {
    margin: ${spacing.md} 0;
    width: 100%;
  }
`

const BottomContent = styled.div`
  transition: ${transitions.page};
  transition-delay: .5s;
  opacity: 0;
  transform: translateX(-${spacing.lg});
  display: inline-block;
  width: 100%;

  &.even { transform: translateX(${spacing.lg}); }
`

const Title = styled.h3`
  margin: 0;
  font-size: 24px;
`

const SubTitle = styled.small`
  margin: ${spacing.sm} 0 ${spacing.lg} 0;
  font-family: ${fonts.action};
  display: block;
  color: ${colors.mediumGrey};
`

const MoreText = styled(Tag)`
  padding: 0 ${spacing.md} ${spacing.md} ${spacing.md};
  margin-left: -${spacing.md};
  display: inline-block;
  position: relative;
  opacity: 1;
  transform: none;
  
  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${colors.grey};
  }
  
  &::after {
    background-color: white;
    transform: scaleX(0);
    transition: .5s ease;
  }
`

const WorkItem = ({name, teaser, even, type, url, tag}) => {
  const navigate = useNavigate()
  const wrapper = useRef(null);

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

  const switchPage = (newUrl = '/') => {
    animateScroll.scrollToTop({
      duration: pageTransitionTimeMs,
      smooth: 'easeInOutQuart'
    })

    navigate(newUrl)
  }

  return (
    <Wrapper ref={wrapper} onClick={() => switchPage(`/projects/${url}`)}>
      <InnerWrapper even={even} >
        <ImageWrapper>
          <Image alt={name} src={teaser} className="image" />
        </ImageWrapper>
      <Content>
        <Tag className={`content ${even ? 'even' : ''}`}>{tag}</Tag>
        <BottomContent className={`content ${even ? 'even' : ''}`}>
          <Title>{name}</Title>
          <SubTitle>{type}</SubTitle>
          <MoreText className="more-text">Learn More</MoreText>
        </BottomContent>
      </Content>
      </InnerWrapper>
    </Wrapper>
  )
}

export default WorkItem

// TODO ADD PROPTYPES
