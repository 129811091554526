import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled";
import {spacing, transitions, fonts, letterSpacing, colors} from "../Styles/variables";
import data from "./resumeContent";
import {ScrollScene} from "scrollscene";
import Slider from "./Slider";
import {Divider, ResumeTitle} from "../Styles/Resume";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGuitar, faFutbolBall, faBeer, faTerminal, faFilm} from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  margin: ${spacing.lg} 0;
  display: flex;
  align-items: center;
  
  @media only screen and (max-width: 992px) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
`

const Hobbies = styled.div`
  margin-top: ${spacing.md};
  font-family: ${fonts.action};
  letter-spacing: ${letterSpacing.action};
  text-transform: uppercase;
  display: flex;
  gap: ${spacing.md};
  align-items: center;
  opacity: .5;
  justify-content: flex-end;

  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`

const HobbyIcon = styled(FontAwesomeIcon)`
`

const WrapperInner = styled.div`
  flex: 0 0 50%;
  padding: ${spacing.md};
  
  @media only screen and (max-width: 992px) {
    flex: 1;
    transform: translateX(0) translateY(${spacing.md});
    padding: ${spacing.md} 0;
  }
  
  &.fade-in {
    transform: translateX(${spacing.md});
    opacity: 0;
    transition: ${transitions.page};
    transition-delay: .5s;
    
    &.in-view {
      transform: translateX(0) translateY(0);
      opacity: 1;
    }
  }
`

const Title = styled(ResumeTitle)`
  margin: 0 0 ${spacing.md};
  text-align: right;
`

const IntroductionText = styled.p`
  font-style: italic;
`

const InverseDivider = styled(Divider)`
  background: linear-gradient(90deg, ${colors.glitchBlue}, ${colors.glitchPink});
`

const Impression = () => {
  const pageWrapper = useRef(null)
  const introText = useRef(null)

  useEffect(() => {
    if(Boolean(introText.current) && Boolean(pageWrapper.current)) {
      new ScrollScene({
        triggerElement: pageWrapper.current,
        toggle: {
          element: introText.current,
          className: "in-view"
        },
      })
    }
  }, [])

  return (
    <Wrapper ref={pageWrapper}>
       <WrapperInner>
         <Slider />
       </WrapperInner>
        <WrapperInner className="fade-in" ref={introText}>
          <Title data-text="ABOUT ME">ABOUT ME</Title>
          <Divider />
          <IntroductionText>
            {data.intro}
          </IntroductionText>
          <InverseDivider />
          <Hobbies>
            Hobbies:
            <HobbyIcon title="Playing electric guitar" icon={faGuitar}/>
            <HobbyIcon title="Playing football" icon={faFutbolBall}/>
            <HobbyIcon title="Having a beer with friends" icon={faBeer}/>
            <HobbyIcon title="Programming" icon={faTerminal}/>
            <HobbyIcon title="Movies/series" icon={faFilm}/>
          </Hobbies>
       </WrapperInner>
    </Wrapper>
  )
}

export default Impression;